<template>
  <div v-if="item">
    <div v-if="viewList" class="video-item-list" :class="{'shadow-none border-0': onlyName}">
      <div class="ratio-custom">
        <div>
          <img class="thumbnail pointer" :src="item.thumbnail" alt="thumbnail" @click="redirectView" />
          <img class="play-video position-absolute pointer" @click="redirectView" src="~/mlearn/icons/video/play.svg" alt="play">
          <span v-if="!isSub">
               <LockFile v-if="!item.is_free"/>
          </span>
        </div>
      </div>
      <div class="content" :class="{'px-0': onlyName}">
        <div class="video-name pointer display-html display-2-line" :class="{'viewer': item.is_viewed}" @click="redirectView">{{ item.name }}</div>
        <div class="video-info" v-if="!onlyName"><span>{{item.class_name}}</span> &#8226; <span>{{item.category_name}}</span></div>
        <div class="topic-info" v-if="!onlyName">
          <div class="text-truncate" v-if="item.topics && item.topics.length">
            <nuxt-link :to="'/chuyen-de/'+item.topics[0].slug" :title="item.topics[0].name">#{{ item.topics[0].name }}</nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="video-item-detail">
      <div class="form-data">
        <div class="thumb ratio-custom">
          <div>
            <img class="thumbnail pointer" :src="item.thumbnail" alt="thumbnail" @click="redirectView" />
            <img class="play-video position-absolute pointer" @click="redirectView" src="~/mlearn/icons/video/play.svg" alt="play">
            <span v-if="!isSub">
               <LockFile v-if="!item.is_free"/>
            </span>
          </div>
        </div>
        <div class="content ml-3" :class="{'px-0': onlyName}">
          <div class="video-name pointer display-html display-1-line" :class="{'viewer': item.is_viewed}" @click="redirectView">{{ item.name }}</div>
          <div class="video-info" v-if="!onlyName"><span>{{item.class_name}}</span> &#8226; <span>{{item.category_name}}</span></div>
          <div class="topic-info display-html display-1-line" v-if="!onlyName">
            <div v-for="topic in item.topics" :key="topic.id">
              <nuxt-link :to="'/chuyen-de/'+topic.slug" :title="topic.name">#{{ topic.name }}</nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSub: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () {
        return null
      }
    },
    updating: {
      type: Boolean,
      default () {
        return false
      }
    },
    viewList: {
      type: Boolean,
      default () {
        return true
      }
    },
    onlyName: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      processing: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    console.log(this.isSub)
  },
  methods: {
    redirectView () {
      if (!this.user) {
        this.$bvModal.show('modal-login')
        return
      }
      this.$helper.checkUserBuySub(this, this.item.is_free, '/video/' + this.item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-current{
  .video-name{
    color: var(--danger) !important;
  }
  .content{
    padding-left: 0;
    padding-right: 0;
  }
}
.video-item-list{
  background: var(--white);
  border: 1px solid #CED4DA;
  border-radius: 8px;
  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    .video-name{
      color: var(--primary) !important;
    }
  }
  .ratio-custom{
    --aspect-ratio: 59.60%;
    .thumbnail{
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .play-video{
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .lock-file{
      background: #e4e4e4;
      border-radius: 0 8px 0 8px;
    }
  }
  .content{
    padding:10px 16px;
    @media (max-width:576px) {
      padding:10px;
    }
    .video-name{
      font-weight: 600;
      min-height: 42px;
      font-size: 14px;
      &.viewer{
        color: #75777B;
      }
    }
    .video-info{
      color: var(--primary);
      font-size: 14px;
      margin-bottom: 8px;
      @media (max-width:576px) {
        font-size: 12px;
        margin-bottom: 3px;
      }
    }
    .topic-info{
      min-height: 28px;
      @media (max-width:576px) {}
      a{
        color: var(--primary);
        font-size: 14px;
        @media (max-width:576px) {
          font-size: 12px;
        }
      }
    }
  }
}
.video-item-detail{
  &:hover{
    .video-name{
      color: var(--primary) !important;
    }
  }
  .form-data{
    .ratio-custom{
      --aspect-ratio: 60%;
      width:166px;
      @media (max-width:1366px) {
        width:120px;
      }
      @media (max-width:576px) {
        width:110px;
      }
      .thumbnail {
        border-radius: 4px;
      }
      .play-video{
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .lock-file{
        background: #e4e4e4;
        border-radius: 0 4px 0 4px;
      }
    }
    .content{
      .video-name{
        font-weight: 600;
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
        }
      }
      .video-info{
        color: var(--primary);
        font-size: 14px;
        margin-bottom: 8px;
        @media (max-width:576px) {
          font-size: 12px;
          margin-bottom: 3px;
        }
      }
      .topic-info{
        a{
          color: var(--primary);
          font-size: 14px;
          @media (max-width:576px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
