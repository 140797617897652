<template>
  <div class="maq">
    <div class="maq-content">
      <div class="qsl-subject">
        <p class="title">Môn học</p>
        <div class="row">
          <div class="col-6 mb-3">
            <button :to="path" class="qsls-item" :class="subject === '' ? 'active' : ''" @click="subject = ''">
              <img src="~/assets/icons/question/all-subject.png" alt="Tất cả"> Tất cả
            </button>
          </div>
          <div v-for="s in subjects" :key="s.id" class="col-6 mb-3">
            <button class="qsls-item" :class="s.slug === subject ? 'active' : ''" @click="subject = s.slug">
              <img :src="'/icon/question/' + s.slug + '.png'" :alt="s.name"> {{ s.name }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="listStatus.length" class="qsl-status-question">
        <p class="title">Trạng thái</p>
        <div class="row">
          <div v-for="s in listStatus" :key="s.value" class="col-6 mb-3">
            <button class="qsls-item" :class="s.value === statusQuestion ? 'active' : ''" @click="statusQuestion = s.value">
              <img :src="'/icon/question/' + s.text + '.png'"> {{ s.text }}
            </button>
          </div>
        </div>
      </div>
      <button type="button" class="apply-btn w-100" @click="onApplyFilter">Áp dụng</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subjects: {
      type: Array,
      default () {
        return []
      }
    },
    listStatus: {
      type: Array,
      default () {
        return []
      }
    },
    slug: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default: null
    },
    path: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      subject: '',
      statusQuestion: ''
    }
  },
  created () {
    this.subject = this.slug
    this.statusQuestion = this.query ? this.query.status : ''
  },
  methods: {
    onApplyFilter () {
      let link = ''
      if (!this.listStatus.length) {
        link = this.subject ? this.path + '/' + this.subject : this.path
        this.$router.push(link)
      } else {
        link = this.subject ? this.path + '/' + this.subject + '?status=' + this.statusQuestion : this.path + '?status=' + this.statusQuestion
        this.$emit('reload', this.statusQuestion, link)
      }

      this.hideModal()
    },
    hideModal () {
      this.$emit('hide-dialog')
    }
  }
}
</script>
<style lang="scss">
#modal-filter-question {
  top: unset;
  z-index: 10000 !important;

  .el-dialog {
    width: 100%;
    margin: 0;
    border-radius: 24px 24px 0px 0px;

    &__close {
      font-weight: 800;
      font-size: 28px;
    }

    &__header {
      display: block;
      border-bottom: 1px solid #EEEEEE !important;
      padding: 21px 0 17px;

      .el-dialog__title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #333333;

      }
    }

    &__body {
      padding: 0;
    }
  }

  .maq {
    .maq-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #333333;
      padding: 21px 0 17px;
      border-bottom: 1px solid #EEEEEE;
    }

    .maq-content {
      padding: 18px;

      .title {
        margin-top: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }

      .qsl-subject {
        .qsls-item {
          padding: 7px 16px;
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          border-radius: 80px;
          background: inherit;
          border: none;

          img {
            margin-top: -4px;
            margin-right: 9px;
          }

          &.active {
            background: #DBE8FF;
            color: #4086FF;
            font-weight: 700;
          }
        }
      }

      .qsl-status-question {
        .qsls-item {
          cursor: pointer;
          padding: 9px 0;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          border: 1px solid #E8E8E8;
          border-radius: 6px;
          background: inherit;

          &.active {
            color: #4086FF;
            font-weight: 700;
            background: #EDF4FF;
            border: 1px solid #EDF4FF;
          }
        }
      }

      .apply-btn {
        margin-top: 14px;
        background: #4086FF;
        border-radius: 65px;
        padding: 11px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        border: none;
      }
    }
  }
}

</style>
