<template>
  <b-modal id="modal-add-question" centered hide-footer hide-header :no-close-on-backdrop="true" :no-close-on-esc="true" body-class="p-0 position-relative" size="xl" v-model="showModalAddQuestion" @show="showModal" @hidden="hiddenModal">
    <img class="close pointer position-absolute" src="~/assets/icons/question/close.png" @click="hideModal"/>
    <div class="maq">
      <div class="maq-title">Đặt câu hỏi bạn muốn CoLearn giải đáp</div>
      <div class="maq-content">
        <div class="qa-bonus">
          Bạn còn lại {{ tutorQuestion }} lượt hỏi bài nhanh và {{ videoQuestion }} lượt hỏi bài video
        </div>
        <div class="box-select">
          <div class="bs-class">
            <b-dropdown class="dropdown-select-class" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret toggle-class="text-left custom-caret custom-select text-truncate w-100" variant="transparent">
              <template #button-content>
                Lớp 12
              </template>
            </b-dropdown>
          </div>
          <div class="bs-subject">
            <b-dropdown class="dropdown-select-subject" ref="dropdown-select-subject" menu-class="menu-class scrollbar mt-0" no-caret toggle-class="text-left custom-caret custom-select text-truncate w-100" variant="transparent">
              <template #button-content>
                {{ subjectActive ? subjectActive.name : subjectPlaceholder }}
              </template>
              <b-dropdown-text v-for="item in subjects" :key="item.id" @click="setSubjectActive(item)" :class="{'active': item.id === question.category_id}">{{ item.name }}</b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="form-check form-check-inline select-type">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="typeRadio1" value="2" checked>
            <label class="form-check-label" for="typeRadio1">Hỏi nhanh</label>
          </div>
          <div class="form-check form-check-inline select-type">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="typeRadio2" value="4" disabled>
            <label class="form-check-label" for="typeRadio2">Hỏi video</label>
          </div>
          <div class="bs-info">
            <el-popover placement="bottom-end" trigger="click" width="320" :visible-arrow="false" popper-class="bs-info-popper">
              <div class="info-title" slot="reference">
                <img src="~/assets/icons/question/info.png" alt="thông tin">Thông tin
              </div>
              <div class="info-content">
                <p>&#8226; Hỏi nhanh: Trả lời trong vòng 30p</p>
                <p>&#8226; Hỏi video: Kết nối trực tiếp vs giáo viên để nhận lời giải</p>
              </div>
            </el-popover>
          </div>
          <div class="box-error" v-if="tutorActive === 0">
            {{ tutorMessage }}
          </div>
        </div>
        <div class="box-editor">
          <TinyEditor v-model="contentQuestion" placeholder="Nhập câu hỏi hoặc gửi hình ảnh (Tối đa 1 ảnh), chú ý không yêu cầu giải toàn bộ đề và hãy chia nhỏ câu hỏi để có lời giải nhanh nhất." :disable="!authenticated" @handle-upload="handleUpload" v-if="showModalAddQuestion"/>
        </div>
        <div class="mqa-image">
          <img :src="imagePreview" alt="" class="q-image">
          <img src="~/assets/icons/question/remove.png" alt="remove" class="remove" @click="removeImage">
        </div>
      </div>
      <div class="maq-button pointer" @click="createQuestion">
        <div class="maqb-btn">
          <img src="~/assets/icons/question/send.png" alt="Gửi câu hỏi"><span>Gửi câu hỏi</span>
        </div>
      </div>
      <input ref="fileInput" type="file" hidden accept=".png, .jpg, .jpeg" @change="changeFile" />
      <b-modal v-model="showCrop" hide-header hide-footer no-close-on-backdrop no-close-on-esc modal-class="modal-crop" size="lg" centered>
        <VueCropper ref="cropper" :src="fileCropper || imagePreview" :auto-crop-area="1"/>
        <div class="crop-action">
          <img src="/icon/layout/rotate.svg" @click="rotate(90)" />
        </div>
        <div class="row no-gutters note align-items-center">
          <div class="col-sm-12 text-right">
            <button @click="cropImage()">Chọn</button>
          </div>
        </div>
      </b-modal>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import { getToken } from '~/common/utils/auth'
import TinyEditor from '~/components/extra/Editor/index'
import Resource from '~/common/api/resource'

const remainResource = new Resource('guru/remain-guru-question')
const tutorActiveResouce = new Resource('qa/checkTutorActive')
const qaResource = new Resource('qa')

export default {
  components: {
    TinyEditor,
    VueCropper
  },
  data () {
    return {
      showModalAddQuestion: false,
      subjectPlaceholder: 'Chọn môn',
      subjectActive: null,
      question: {
        question_type: 2,
        class_id: '',
        category_id: '',
        content: '',
        image: []
      },
      contentQuestion: '',
      tutorQuestion: 0,
      videoQuestion: 0,
      tutorActive: 1,
      tutorMessage: '',
      showCrop: false,
      fileCropper: '',
      imagePreview: '',
      imageUpload: '',
      image: []
    }
  },
  computed: {
    ...mapState([
      'authenticated'
    ]),
    subjects () {
      return this.$store.state.context.subjects
    },
    classId () {
      return this.$store.state.user.classId
    }
  },
  methods: {
    hideModal () {
      this.showModalAddQuestion = false
    },
    async setSubjectActive (item) {
      this.$refs['dropdown-select-subject'].hide(true)
      this.subjectActive = item
      this.question.category_id = item.id
      const { data } = await tutorActiveResouce.list({
        class_id: this.classId,
        category_id: item.id
      })
        .catch(() => {})
      this.tutorActive = data.tutor_active
      this.tutorMessage = data.text
    },
    showModal () {
      this.getRemainQuestion()
    },
    hiddenModal () {
      this.resetModal()
    },
    resetModal () {
      this.contentQuestion = ''
      this.subjectPlaceholder = 'Chọn môn'
      this.subjectActive = null
      this.question.category_id = ''
      this.tutorActive = 1
      this.tutorMessage = ''
    },
    async getRemainQuestion () {
      const { data } = await remainResource.list().catch(() => {})
      this.tutorQuestion = data.remain_question || 0
      this.videoQuestion = data.remain_video_question || 0
    },
    handleUpload () {
      this.$refs.fileInput.click()
    },
    changeFile () {
      const files = this.$refs.fileInput.files
      if (files.length === 0) { return }
      const x = 0
      const file = files[x]
      const size = file.size
      if (size === 0) {
        this.$notify({
          title: 'Lỗi',
          message: 'Ảnh lỗi',
          type: 'error'
        })
        return
      }
      const limit = 8
      if (size > limit * 1024 * 1024) {
        this.$notify({
          title: 'Lỗi',
          message: `Dung lượng không vượt quá ${limit}MB`,
          type: 'error'
        })
        return
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        this.fileCropper = e.target.result
        this.showCrop = true
        // this.$refs.cropper.replace(e.target.result)
      }
      reader.readAsDataURL(file)
      this.$refs.fileInput.value = ''
    },
    cropImage () {
      const copper = this.$refs.cropper.getCroppedCanvas()
      const image = copper.toDataURL()
      copper.toBlob((blob) => {
        this.showCrop = false
        this.imagePreview = image
        this.imageUpload = blob
        this.uploadImg()
      }, 'image/png')
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    uploadImg () {
      if (!this.imageUpload) { return }
      const token = getToken()
      const formData = new FormData()
      formData.append('file', this.imageUpload)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/qa/image', formData, config)
        .then((response) => {
          if (response.status === 200) {
            this.image = response.data.data.map(function (data) {
              return data.fileDownloadUri
            })
          }
        }).catch((err) => {
          this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
          this.image = []
        })
    },
    removeImage () {
      this.imagePreview = ''
      this.imageUpload = ''
      this.image = []
    },
    createQuestion () {
      this.question.class_id = this.classId
      this.question.content = this.contentQuestion
      this.question.image = this.image
      qaResource.store(this.question)
        .then((response) => {
          this.resetModal()
          this.$router.push('/cau-hoi/' + response.data.id)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss">
#modal-add-question {
  .close {
    top: 26px;
    right: 32px;
  }
  .modal-dialog {
    max-width: 900px;
    .modal-content {
      border-radius: 16px;
    }
  }
  .maq {
    .maq-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.1px;
      color: #333333;
      padding: 16px 0;
      border-bottom: 1px solid #EEEEEE;
    }
    .maq-content {
      padding: 12px 20px;
      margin-bottom: 24px;
      .qa-bonus {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #22313F;
        margin-bottom: 16px;
      }
      .box-select {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .custom-select {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.1px;
          color: #22313F;
          border: 1px solid #B8BDC2;
          border-radius: 12px;
          padding: 12px 50px 12px 12px;
          height: auto;
          background-image: url("/icon/layout/drop-down.png");
          background-repeat: no-repeat;
          background-position: right 1.5rem center;
          background-size: 14px 11px;
          &:focus {
            box-shadow: none !important;
          }
        }
        ul.dropdown-menu {
          border: 1px solid #CBCBCB;
          box-shadow: 0px 10px 20px rgba(34, 49, 63, 0.2);
          border-radius: 12px;
          padding: 0;
          li {
            cursor: pointer;
            padding: 0 12px;
            .b-dropdown-text {
              font-weight: 400;
              font-size: 18px;
              letter-spacing: 0.1px;
              color: #333333;
              padding: 12px 0;
              border-bottom: 1px solid #EEEEEE;
            }
          }
        }
        .bs-class {
          margin-right: 20px;
          .dropdown-menu {
            display: none !important;
          }
        }
        .bs-subject {
          margin-right: 20px;
          .custom-select {
            width: 155px !important;
          }
        }
        .select-type {
          margin-right: 19px;
          .form-check-label {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.1px;
            color: #22313F;
          }
          .form-check-input {
            cursor: pointer;
            width: 18px;
            height: 18px;
            margin-right: 11px;
          }
        }
        .bs-info {
          position: relative;
          margin-left: auto;
          .info-title {
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: 0.1px;
            color: #22313F;
            img {
              margin-right: 11px;
            }
          }
        }
        .box-error {
          position: absolute;
          font-weight: 400;
          font-size: 16px;
          line-height: 17px;
          color: #FF2103;
          left: 0;
          bottom: -23px;
        }
      }
      .box-editor {}
    }
    .mqa-image {
      position: relative;
      display: inline-block;
      margin-top: 16px;
      border-radius: 8px;
      .q-image {
        border-radius: 8px;
        max-height: 80px;
        width: auto;
        z-index: 9;
      }
      .remove {
        position: absolute;
        top: 3px;
        right: 3px;
        cursor: pointer;
        z-index: 10;
      }
    }
    .maq-button {
      padding: 0 20px 24px 20px;
      display: flex;
      align-items: center;
      .maqb-btn {
        margin-left: auto;
        background: #4086FF;
        border-radius: 12px;
        width: 190px;
        text-align: center;
        padding: 14px 0;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        img {
          margin-right: 11px;
        }
      }
    }
  }
}
.el-popover {
  &.bs-info-popper {
    box-shadow: 0px 10px 20px rgba(34, 49, 63, 0.2);
    border-radius: 12px;
    padding: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #22313F;
    .info-content {
      p {
        margin-bottom: 0;
        word-wrap: break-word;
      }
    }
  }
}
</style>
