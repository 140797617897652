<template>
  <b-modal id="modal-add-question-error" centered hide-footer hide-header :no-close-on-backdrop="true" :no-close-on-esc="true" body-class="p-0 position-relative" size="xl" v-model="showModal">
    <img class="close pointer position-absolute" src="~/assets/icons/question/close.png" @click="hideModal"/>
    <div class="maqe">
      <div class="maqe-title">{{ code === 403 ? 'Bạn đã hết lượt hỏi bài' : 'Hết lượt hỏi bài' }} </div>
      <div class="maqe-content" v-if="code === 401">
        {{ content }}
      </div>
      <div class="maqe-content" v-else>
        <div class="maqe-bonus">
          <div class="bonus-item d-flex align-items-center pointer" v-for="bonus in bonusData" :key="bonus.target" @click="toTarget(bonus.target)">
            <div class="bi-img">
              <img :src="bonus.image" :alt="bonus.name">
            </div>
            <div class="bi-content">
              <div class="bic-title">{{ bonus.name }}</div>
              <div class="bic-des">{{ bonus.description }}</div>
            </div>
            <img src="~/assets/icons/question/arrow_right.png" alt="arrow right" class="bi-arrow">
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
// import Resource from '~/common/api/resource'
// const bonusResource = new Resource('config/question/bonus')

export default {
  props: {
    code: {
      type: Number,
      default: 403
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModal: false,
      bonusData: []
    }
  },
  created () {
    this.bonusQuestion()
  },
  methods: {
    hideModal () {
      this.showModal = false
    },
    async bonusQuestion () {
      // const { data } = await bonusResource.list().catch(() => {
      //   console.log('Null bonusQuestion')
      // })
      // this.bonusData = data
    },
    toTarget (target) {
      if (target === 'BUY_SUB') {
        return this.$router.push('/dang-ky/goi-thanh-vien')
      }
    }
  }
}
</script>
<style lang="scss">
#modal-add-question-error {
  .close {
    top: 13px;
    right: 13px;
  }
  .modal-dialog {
    max-width: 532px;
    .modal-content {
      border-radius: 16px;
    }
  }
  .maqe {
    .maqe-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 180%;
      text-align: center;
      color: #333333;
      padding: 13px 0;
    }
    .maqe-content {
      padding-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #333333;
      .maqe-bonus {
        padding: 0 15px 0 11px;
        .bonus-item {
          background: #FFFFFF;
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
          border-radius: 14px;
          margin-bottom: 25px;
          padding: 10px 15px 17px 11px;
          .bi-img {
            margin-right: 10px;
          }
          .bi-content {
            text-align: left;
            .bic-title {
              font-weight: 700;
              font-size: 14px;
              line-height: 24px;
              color: #4086FF;
            }
            .bic-des {
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #888888;
            }
          }
          .bi-arrow {
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
